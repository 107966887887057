<template>
  <div class="verificationList">
    <div class="search">
      <div class="search-l">
        <v-dropDownMenu
          v-model="requestData.status"
          :option1="statusOps"
          @change="change"
        ></v-dropDownMenu>
      </div>
      <div class="search-r">
        <v-search
          v-model="value"
          @onSearch="onSearch"
          placeholder="请输入姓名/手机号"
        ></v-search>
      </div>
    </div>
    <div class="listbody">
      <v-list
        :finished="finished"
        :finishedText="finishedText"
        @onLoad="onLoad"
        ref="list"
      >
        <div
          class="order"
          v-for="(item, index) in listData"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="order-t">
            <div class="order-tl">
              <div class="text">姓名：</div>
              <div class="txt">{{ item.receiverName }}</div>
            </div>
            <div :class="['order-tr', { active: item.status == 3 }]">
              {{ returnStatus(item.status) }}
            </div>
          </div>
          <div class="order-b">
            <div class="order-bl">
              <img :src="item.skuImageUrl" alt="" />
            </div>
            <div class="order-br">
              <div class="title">{{ item.changeTitle }}</div>
              <div class="price">
                <span>{{ item.pointsPrice }}</span
                >积分
              </div>
            </div>
            <div
              class="handle"
              v-if="requestData.status == 1"
              @click.stop="toHandle(item)"
            >
              核销
            </div>
          </div>
          <div :class="['people', { active: item.status == 3 }]">
            <div :class="['tel', { active: item.status == 3 }]">
              <div class="text">手机号：</div>
              <div class="txt">{{ item.receiverPhone }}</div>
            </div>
            <div class="time">
              <div class="text">兑换时间：</div>
              <div class="txt">{{ item.insertTime }}</div>
            </div>
            <div class="time" v-if="requestData.status == 3">
              <div class="text">核销时间：</div>
              <div class="txt">{{ item.completedTime }}</div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
    <v-dialog v-model="isDialog" title="提示" @confirm="confirm">
      <div class="tips">
        <div class="tips-t">确定要核销兑换商品吗？</div>
        <div class="tips-b">
          <div class="tips-bl">
            <img src="@/assets/img/notice.png" alt="" />
          </div>
          <div class="tips-br">请仔细核对哦</div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { getOrderListUrl, handleOrderUrl } from "./api.js";
import { statusOps } from "./map.js";
import { getOption, handleImg } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "points",
  data() {
    return {
      statusOps,
      isDialog: false,
      value: "",
      finishedText: "没有更多了",
      finished: false,
      requestData: {
        status: "1",
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
      immediateCheck: true,
      id: "",
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {},
  methods: {
    returnStatus(status) {
      if (status) {
        return getOption(status, statusOps, "value")["text"];
      } else {
        return "待核销";
      }
    },
    change(value) {
      this.requestData.status = value;
      this.selectOrder();
    },
    toHandle(item) {
      this.isDialog = true;
      this.id = item.id;
    },
    confirm() {
      this.isDialog = false;
      this.handle();
    },
    handle() {
      let params = {
        id: this.id,
        userId: this.userId,
      };
      this.$axios
        .post(`${handleOrderUrl}`, this.$qs.stringify(params))
        .then((res) => {
          console.log(res, 5555);
          if (res.code === 200) {
            this.$toast({ message: "核销成功", duration: 2000 });
            this.onSearch();
          } else {
          }
        });
    },
    onSearch() {
      this.requestData.curPage = 1;
      this.listData = [];
      this.finished = false;
      this.getActivityList();
    },
    selectOrder() {
      this.requestData.curPage = 1;
      this.finished = false;
      this.listData = [];
      this.getActivityList();
    },
    toDetail(item) {
      this.$router.push({
        name: "verificationDetail",
        query: { id: item.id },
      });
    },
    onLoad() {
      this.getActivityList();
    },
    getActivityList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        status: this.requestData.status,
        userId: this.userId,
        keyword: this.value,
      };
      this.$axios.get(`${getOrderListUrl}`, { params: params }).then((res) => {
        console.log(res, 333);
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.skuImageUrl) {
              item.skuImageUrl = handleImg(300, 300, item.skuImageUrl);
            }
            this.listData.push(item);
          });
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.verificationList {
  background: #f5f5f5;
  min-height: 100vh;
  .search {
    position: fixed;
    height: 96px;
    background: #fff;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 99;
    display: flex;
    .search-l {
      width: 224px;
    }
    .search-r {
      display: flex;
      align-items: center;
    }
  }
  .listbody {
    padding: 128px 32px 32px;
  }
  .order {
    height: 396px;
    background: #ffffff;
    border-radius: 16px;
    margin-bottom: 32px;
    .order-t {
      font-size: 30px;
      padding: 26px 32px;
      display: flex;
      justify-content: space-between;
      box-shadow: 0px -1px 0px 0px rgba(245, 245, 245, 1);
      border-bottom: 1px solid rgba(245, 245, 245, 1);
      .order-tl {
        display: flex;
        font-size: 32px;
        font-weight: 700;
        color: #323334;
        line-height: 44px;
        .text {
          margin-right: 32px;
        }
      }
      .order-tr {
        color: #fe6f16;
      }
      .active {
        color: #007eff;
      }
    }
    .order-b {
      display: flex;
      position: relative;
      align-items: center;
      padding: 12px 32px;
      .order-bl {
        width: 128px;
        height: 128px;
        margin-right: 16px;
        background: #f6f6f6;
        border-radius: 10px;
        text-align: center;
        img {
          width: 60%;
          height: 60%;
          border-radius: 10px;
          vertical-align: middle;
        }
        &:after {
          content: "";
          display: inline-block;
          width: 0;
          height: 100%;
          vertical-align: middle;
        }
      }
      .order-br {
        .title {
          margin: 6px 0 20px 0;
          font-weight: 600;
          font-size: 32px;
          color: #323334;
          margin-bottom: 22px;
        }
        .price {
          color: rgba(0, 126, 255, 1);
          font-size: 24px;
          span {
            font-size: 40px;
            font-weight: 600;
            color: #fe6f16;
            line-height: 56px;
            margin-right: 8px;
          }
        }
      }
      .handle {
        width: 160px;
        position: absolute;
        right: 32px;
        bottom: 20px;
        background: rgba(0, 126, 255, 1);
        color: #fff;
        text-align: center;
        font-size: 24px;
        height: 60px;
        border-radius: 40px;
        line-height: 60px;
      }
    }
    .people {
      padding: 20px 32px 0px;
      .tel,
      .time {
        display: flex;
        font-size: 28px;
        font-weight: 400;
        color: #646668;
        line-height: 40px;
      }
      .tel {
        margin-bottom: 16px;
      }
      .active {
        margin: 0;
      }
    }
    .people.active {
      padding: 10px 32px 0px;
    }
  }
  .tips {
    .tips-t {
      font-size: 34px;
      text-align: center;
      padding: 0 20px;
      margin: 40px 0;
    }
    .tips-b {
      display: flex;
      margin: 40px 0;
      justify-content: center;
      align-items: center;
      .tips-bl {
        width: 30px;
        height: 30px;
        font-size: 0;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .tips-br {
        font-size: 28px;
      }
    }
  }
}
</style>
<style lang="less">
.verificationList {
  .van-dropdown-menu__bar {
    box-shadow: none;
    .van-dropdown-menu__title {
      font-size: 32px;
      font-weight: 700;
      color: #323334;
    }
  }
  .van-search {
    padding: 0;
  }
  .van-search .van-cell {
    align-items: center;
  }
  .van-search__content {
    width: 494px;
    height: 72px;
    background: #f5f5f5;
    border-radius: 40px;
  }
  .van-icon-search::before {
    content: "";
    width: 32px;
    background: url("./img/search.png") no-repeat;
    height: 32px;
    background-size: 100% 100%;
  }
  .van-dropdown-menu__title::after {
    border-color: transparent transparent #323334 #323334;
  }
  .van-dropdown-menu__title--down::after {
    border-color: transparent transparent #007eff #007eff;
  }
}
</style>
