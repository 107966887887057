const pointType = [
  { key: 1, value: "活动获取" },
  { key: 2, value: "扫码获取" },
  { key: 3, value: "未来心愿" },
  { key: 4, value: "社区共建" },
  { key: 5, value: "公益捐赠" },
  { key: 9, value: "积分商城兑换" },
  { key: 8, value: "和伙人申请" },
  { key: 0, value: "积分扫码扣除" },
  { key: 11, value: "用户注册" },
  { key: 12, value: "每日签到" },
];
const statusOps = [
  {
    value: "1",
    text: "待核销",
  },
  {
    value: "3",
    text: "已完成",
  },
];

export { pointType, statusOps };
